<template>
  <div class="contact-form">
    <v-form ref="form" class="contact-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Building</label>
      <SelectMultipleBuildings
        flat
        solo
        required
        class="mt-2"
        :label="'Select buildings'"
        :placeholder="'Select buildings'"
        v-model="form.building_id"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('building_id')"
        :loading="form.$busy"
        :disabled="form.$busy"
        :deletableChips="true"
        :isHasSelectAll="true"
      />

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Name</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Full Name"
        v-model="form.name"
        :error-messages="form.$getError('name')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Title</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        v-model="form.title"
        placeholder="Title"
        :error-messages="form.$getError('title')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />

      <label class="text-field-label">Mobile number</label>
      <v-text-field
        flat
        solo
        hide-details="auto"
        class="mt-2"
        v-model="form.phone_number"
        placeholder="Phone number"
        :required="isUpdate"
        :error-messages="form.$getError('phone_number')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />
      <p class="hint">Mobile Number for SMS function</p>

      <label class="text-field-label">Telephone number</label>
      <v-text-field
        flat
        solo
        hide-details="auto"
        class="mt-2"
        v-model="form.telephone_number"
        placeholder="Phone number"
        :required="isUpdate"
        :error-messages="form.$getError('telephone_number')"
        :loading="form.$busy"
        :disabled="form.$busy"
      />
      <p class="hint">Telephone number for call function</p>

      <label class="text-field-label">Email</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Email"
        v-model="form.email"
        :error-messages="form.$getError('email')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <div class="mt-10">
        <v-btn
          v-if="hasUpdatePermission"
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          :disabled="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Contact Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { isValidMobileNumber, isValidTelephoneNumber } from '@/utils/phone'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    contact: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        title: '',
        name: '',
        phone_number: '',
        telephone_number: '',
        email: '',
      }),
      icons: {
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
    }
  },

  computed: {
    ...mapState({
      registeredContact: (state) => state.contact.contactDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Contact Information' : 'Create Contact'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateContact : this.registerContact
    },

    isUpdate() {
      return !!this.contact
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.CONTACTS_UPDATE], this.permissions)
    },
  },

  watch: {
    contact(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      registerContact: 'contact/registerContact',
      updateContact: 'contact/updateContact',
    }),

    initForm() {
      if (this.contact) {
        this.form.title = this.contact.title
        this.form.name = this.contact.name
        this.form.phone_number = this.contact.phone_number
        this.form.telephone_number = this.contact.telephone_number
        this.form.email = this.contact.email

        const buildings = [...this.contact.buildings].map((item) => item.id)
        this.form.building_id = buildings
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      if (this.form.phone_number !== null) {
        this.form.phone_number = this.form.phone_number.replace(/\s/g, '')
      }

      if (this.form.telephone_number !== null) {
        this.form.telephone_number = this.form.telephone_number.replace(
          /\s/g,
          ''
        )
      }

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Contact details successfully updated!')
          } else {
            this.showSnackbar('Contact successfully created!')
            this.$router.push({
              name: 'hub.contact-directory',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (
        this.form.phone_number &&
        !isValidMobileNumber(this.form.phone_number)
      ) {
        this.form.$setError(
          'phone_number',
          'Mobile number must be valid or in national format'
        )
      }

      if (
        this.form.telephone_number &&
        !isValidTelephoneNumber(this.form.telephone_number)
      ) {
        this.form.$setError('telephone_number', 'Telephone number is invalid')
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.contact.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.contact-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
